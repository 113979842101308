* {
  box-sizing: border-box;
  font-family: "Stag Sans Book", "Helvetica Neue", sans-serif;
}

html {
  font-size: 8px;
  height: 0 !important;
}

body {
  background: #f7f7f7 !important;
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

div,
span,
p,
a,
section,
input,
article,
li {
  font-size: 2rem;
  text-decoration: none;
}

*::-webkit-scrollbar {
  width: 1rem;
  height: 1rem;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

*::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #99b3c8;
  outline: 1px solid #99b3c8;
}
